
/*============================
	1. General
	2. Table
	3. Bootstrap Classes
	4. owl carousel
	5. breadcrumb
	6. Tab design
	7. Modal css
	8. Accordion Css
	9. Custome Spacing Css
	10. Loader Css
	11. Responsive
============================ */ 

/*-----------------
	1. General
-----------------------*/ 

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');

body {
    background-color:#f5f8fa;
    color: #272b41;
    font-family: "Poppins",sans-serif;
    font-size: 0.9375rem;
    height: 100%;
    overflow-x: hidden;
}
body , h1, h2, h3, h4, h5, h6,p, span, div {
    font-family: "Poppins",sans-serif;
}

html {
    height: 100%;
}

.mob-wrapper {
	display: none;
}
h1, h2, h3, h4, h5, h6 {
	color: #272b41;
	font-weight: 500;
}
.h1, h1 {
    font-size: 2.25rem;
}
.h2, h2 {
    font-size: 2.875rem;
}
.h3, h3 {
    font-size: 1.5rem;
}
.h4, h4 {
    font-size: 1.125rem;
}
.h5, h5 {
    font-size: 1rem;
}
.h6, h6 {
    font-size: 0.875rem;
}

a {
    color: #2E3842;
}
a:hover {
	color: #09dca4;
}
a,
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button:focus {
	outline: 0;
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.btn.focus, .btn:focus ,
body .btn-primary:active:focus {
	box-shadow: inherit;
	outline: none;
	box-shadow: none;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-control {
    border: 1px solid #C4C4C4;
    color: #000;
	font-size: 15px;
    min-height: 46px;
	padding: 6px 15px;
}
.table .form-control {
	font-size: 14px;
	min-height: 38px;
}
.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
.form-control::-webkit-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::-moz-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control:-ms-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::-ms-input-placeholder {
	color: #858585;
	font-size: 14px;
}
.form-control::placeholder {
	color: #858585;
	font-size: 14px;
}
span.eye-icon {
    position: absolute;
    right: 5px;
    top: 42px;
}
.hide {
    display: none;
}
.show {
    display: block;
}
.list-group-item {
	border: 1px solid #f0f0f0;
}
.content {
	min-height: 200px;
	padding: 30px 0 0;
}
.form-group {
    margin-bottom: 1rem;
}
.form-control[type=file] {
	min-height: auto;
}
.pull-right {
    float: right;
}
body .css-1s2u09g-control {
	min-height: 43px;
}
.text-red {
    color: #d72c2c;
}
/*-----------------
	2. Table
-----------------------*/

.table {
	color: #272b41;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #272b41;
}
table.table td h2 a:hover {
	color: #10DEFD;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.table>:not(:first-child) {
	border: 0;
}
table.table td,table.table th {
	border-color: #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}
/*=======
table-style-1 
==========*/

table.table-style-1 {
	border-spacing: 0 15px;
    border-collapse: separate;
}
 table.table-style-1 >thead {
    vertical-align: bottom;
}
 table.table-style-1 tr {
    border: 0 solid;
    border-color: inherit;
}

table.table-style-1 tbody tr {
    background: #fff;
}
 table.table-style-1 tbody tr td {
    border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	vertical-align: middle;
}
 table.table-style-1 tbody td:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-left: 15px;
}
 table.table-style-1 tr td:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-right: 15px;
}
table.table-style-1 thead tr th {
    border-bottom: none;
    font-weight: 600;
    color: #757575;
}
/*=======
table-style-1  for white bg
==========*/
.table-white-bg table.table-style-1 tbody tr td ,
.table-white-bg table.table-style-1 thead tr th {
    border-top: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.table-white-bg table.table-style-1 tbody tr td:first-child,
.table-white-bg table.table-style-1 thead tr th:first-child {
    border-left: 1px solid rgba(0,0,0,.125);
}
.table-white-bg table.table-style-1 tbody tr td:last-child,
.table-white-bg table.table-style-1 thead tr th:last-child {
    border-right: 1px solid rgba(0,0,0,.125);
}
.table-white-bg table.table-style-1 body tr td:first-child,
.table-white-bg table.table-style-1 thead tr th:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-left: 15px;
}
.table-white-bg table.table-style-1 tbody tr td:last-child,
.table-white-bg table.table-style-1 thead tr th:last-child  {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-right: 15px;
}
.table-white-bg table.table-style-1 tbody tr:hover  td{
	background: #f8f7f7;
}
.table-white-bg table.table-style-1 {
	border-spacing: 0 10px;
}
.table-white-bg table.table-style-1 thead tr th {
    color: #757575;
    padding: 10px;
    background: #f8f7f7;
}
.table-white-bg table .table>:not(caption)>*>* {
	padding: 10px;
}


/*-----------------
	3. Bootstrap Classes
-----------------------*/
.btn {
	font-size: 15px;
}
.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #272b41;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #09e5ab !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #10DEFD !important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #20c0f3 !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #0071DC !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ff0100 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary, .btn-danger {
    padding: 9px 20px;
    min-width: 175px;
    padding: 9px 20px;
    margin: auto;
    font-size: 16px;
    color: #fff;

}
.btn-primary {
	background-color: #71af2f;
	border: 1px solid #71af2f;
}

.btn-primary a {
	color: #fff;
}
.btn-primary:hover a {
	color: #0071DC;
}
.btn-primary:hover, .btn-danger:hover {
	background: #ffffff;
	box-shadow: inset 210px 0 0 0 #ffffff;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
}
.btn-primary:hover {
	color: #0071DC;
    border: 1px solid #0071DC;
}
.btn-danger:hover {
	color: #dc3545;
    border: 1px solid #dc3545;
 }
.btn-danger:hover a {
	color: #dc3545;
}
.btn-one {
	font-size: 16px;
    font-weight: 700;
    color: #fff;
    /* font-family: 'Encode Sans', sans-serif; */
    padding: 10px 30px;
    text-align: center;
    background: #0071DC;
    border: 1px solid #0071DC;
    box-shadow: inset 0 0 0 0 #ffffff;
    border-radius: 5px;
    min-width: 110px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-one:hover {
    color: #0071DC;
    border: 1px solid #0071DC;
    background: #ffffff;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-one-light {
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    background: #0071DC;
    border: 1px solid #0071DC;
    box-shadow: inset 0 0 0 0 #09e5ab;
    border-radius: 5px;
    min-width: 110px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-one-light:hover {
    color: #0071DC;
    border: 1px solid #09e5ab;
    background: #09e5ab;
    box-shadow: inset 0 50px 0 0 #09e5ab;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-two {
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	font-family: 'Encode Sans', sans-serif;
	padding: 10px 30px;
	text-align: center;
	background: #222b78;
	border: 1px solid #222b78;
	box-shadow: inset 0 0 0 #fff;
	border-radius: 5px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	position: relative;
}
.btn-two:hover {
    color: #242424;
    border: 1px solid #242424;
    background: #ffffff;
    box-shadow: inset 210px 0 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn.mr-10 {
	margin-right: 10px;

}

.theme-btn {
	display: inline-block;
	background: #fff;
	border: 1px solid #0071DC;
	padding: 10px 30px;
	color: #fff;
	border-radius: 5px;
	font-weight: 700;
	font-size: 16px;
	position: relative;
	z-index: 1;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.theme-btn.btn-four {
	color: #fff;
	background: #0071DC;
}
.theme-btn.btn-four:hover {
	color: #0071DC;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.theme-btn.btn-four:after {
	position: absolute;
	right: 0;
	top: 0;
	width: 0%;
	height: 100%;
	content: "";
	background-color: #fff;
	z-index: -1;
	border-radius: 5px;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.theme-btn.btn-four:hover:after {
	width: 100%;
	left: 0;
	right: auto;
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn.btn-four-light {
	color: #0071DC;
	border: 1px solid #0071DC;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.theme-btn.btn-four-light:hover {
	border: 1px solid #0071DC;
	color: #fff;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.theme-btn.btn-four-light:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 0%;
	height: 100%;
	z-index: -1;
	border-radius: 5px;
	-webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.theme-btn.btn-four-light:hover:after {
	left: 0px;
	width: 100%;
	height: 100%;
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.theme-btn.btn-four-light:after {
	background:#0071DC;
}
.text-add-btn {
     border: none;
    color: #20c0f3;
    background: transparent;
    width: 100%;
    text-align: right;
	font-size: 15px;
}

.btn-five {
	background: #063B6F;
    border: 1px solid #063B6F;
    min-width: 175px;
    padding: 9px 20px;
    margin: auto;
	font-size: 16px;
	color: #fff;
}

.btn-five i {
    font-size: 20px;
    position: relative;
    left: 0;
    color: #fff;
    transition: all 0.5s;
}
.btn-five:hover i {
    position: relative;
    left: 5px;
    color: #063B6F;
     -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-five:hover {
    color: #063B6F;
    border: 1px solid #063B6F;
    background: #ffffff;
    box-shadow: inset 210px 0 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-five-light {
	font-size: 15px;
    font-weight: 600;
    color: #063B6F;
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #063B6F;
    box-shadow: inset 0 0 0 #10DEFD;
    border-radius: 5px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
}
.btn-five-light:hover {
    color: #fff;
    border: 1px solid #10DEFD;
    background: #10DEFD;
    box-shadow: inset 210px 0 0 0 #10DEFD;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-six {
	font-size: 16px;
    font-weight: 600;
    color: #fff;
    display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
    text-align: center;
    background: #063B6F;
    border: 1px solid #063B6F;
    box-shadow: inset 0 0 0 #fff;
    border-radius: 5px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
}
.btn-six:hover {
    color: #063B6F;
    border: 1px solid #063B6F;
    background: #ffffff;
    box-shadow: inset 210px 0 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.add-btn {
    max-width: 140px;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #20c0f3;
	border-color: #20c0f3;
}
.pagination > li > a, 
.pagination > li > span {
	color: #20c0f3;
}
.page-link:hover {
	color: #20c0f3;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #20c0f3;
	border-color: #20c0f3;
}
.dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: inherit;
	font-size: 14px;
    transform-origin: left top 0;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #0071DC;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.card {
	border: none;
    margin-bottom: 1.875rem;
    border-radius: 7px;
    box-shadow: 0 0.76rem 1.52rem rgb(18 38 63 / 3%);
    border-radius: 0.3rem;
}
.card.card-style-remove {
	background: transparent;
	box-shadow: none;
}
.card.card-style{	    
    box-shadow: none;
    border: 1px solid #eee;
    margin-bottom: 0.6rem;
}
.card-body {
	padding: 1.5rem;
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #20c0f3;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #0071DC;
}
.bg-info-light {
	background-color: rgb(2 182 179 / 26%) !important;
    color: #0f4842 !important;
}
.bg-primary-light {
	background-color: rgb(17 148 247 / 43%) !important;
    color: #02121e !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgb(255 152 0 / 42%) !important;
    color: #f14327 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgb(197 128 255 / 55%) !important;
    color: #443055 !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}
.form-group {
	margin-bottom: 1.25rem;
}
a.add-more {
    color: #222b78;
}
.delete-more {
	padding-top: 35px;
}
body .btn-small.btn {
    padding: 9px 6px;
    font-size: 11px;
    font-weight: 500;
	box-shadow: none;
	margin-right: 5px;
}
body .btn-small .icon, body .btn-small i {
    margin-right: 6px;
    font-size: 14px;
    line-height: 1;
}
body .btn-view {
    background: #a2c8ed;
    color: #182388;
}
body .btn-cancel {
    background: #e99494;
    color: #d30d0d;
}
.section-header {
    margin-bottom: 60px;
}
.section-header {
    margin-bottom: 60px;
}
.section-header h2 {
    font-size: 36px;
    margin-bottom: 0;
	font-weight: 500;
	position: relative;
    z-index: 9;
}
.section-header .color-primary {
	color: #063b6f;
	font-weight: 700;
}
.section-header .color-grey {
	color: #3E5463;
}
.section-header .sub-title {
    color: #757575;
    font-size: 16px;
    max-width: 600px;
    margin: 20px auto 0;
}
.section-header p {
    color: #757575;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 15px;
}
.banner-wrapper {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
}
.banner-wrapper .banner-header {
	margin-bottom: 30px;
}
.banner-wrapper .banner-header h1 {
    margin-bottom: 10px;
	font-size: 40px;
	font-weight: 600;
}
.banner-wrapper .banner-header p {
	color: #757575;
	font-size: 20px;
	margin-bottom: 0;
}



/*-----------------
	4. owl carousel
-----------------------*/ 
.owl-nav button span {
    background: #fff;
    padding: 0;
    border-radius: 50px;
    height: 35px;
    width: 35px;
    display: block;
    line-height: 34px;
    font-size: 27px;
    color: #222b78;
}
.owl-theme .owl-nav {
  position: absolute;
    top: 48%;
    width: 100%;
}
.owl-nav button.owl-prev {
    left: 20px;
    position: absolute;
}

.owl-nav button.owl-next {
    right: 20px;
    position: absolute;
}
body .owl-theme .owl-nav [class*='owl-']:hover {
    background: none;
}


/*-----------------
	5. breadcrumb
-----------------------*/ 

.breadcrumb-bar {
    background-color: #15558d;
    padding: 15px 0;
}
.breadcrumb-bar .breadcrumb-title {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    margin: 5px 0 0;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 12px;
    margin-bottom: 0;
    padding: 0;
}
.page-breadcrumb ol li a {
    color: #fff;
}
.page-breadcrumb ol li.active {
    color: #fff;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	color: #fff;
	font-size: 10px;
}


/*-----------------
	 6. Tab design
-----------------------*/
.user-tabs  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    background: #fff;
}
.tab-style-4 .nav-item {
	width: 25%;
}
.user-tabs li button {
    border: 0;
    border-bottom: 3px solid transparent;
    color: #3e3e3e;
    font-weight: 600;
    padding: 20px;
}
.user-tabs .nav-tabs .nav-item.show .nav-link, .user-tabs .nav-tabs .nav-link.active {
    color: #20c0f3;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #20c0f3;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
}
.user-tabs .nav-tabs .nav-link {
	width: 100%;;
	background-color: #fff;
}
.user-tabs .nav-tabs  li .nav-link:focus, .user-tabs .nav-tabs  li .nav-link:hover {
    border-left-color: #fff;
    border-right-color: #fff;
    border-top-color: #fff;
}

/*-----------------
	7. Modal css
-----------------------*/
.modal h5 {
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 400;
}
.modal .modal-header {
	color: #222b78;
	border-bottom: 1px dotted #ddd;
	display: block;
}
.modal  .modal-footer {
	border-top: 1px dotted #ddd;
}
.modal .modal-footer {
	text-align: center;
}
.modal .modal-footer .btn {
	min-width: 139px;
    padding: 9px 7px;
	font-size: 13px;
	margin: 5px;
}
.modal .modal-footer {
	display: block;
}
.modal  .breadcrumb-bar {
    display: none;
}
.modal .modal-dialog.modal-md {
    max-width: 900px;
}
.fade.modal.show {
    padding-left: 0 !important;
}
.bg-modal {
	background: #fdfdfd;
}
.modal .modal-header .btn-close {
    opacity: 1;
	padding: 20px;
	position: absolute;
    right: 14px;
    top: 13px;
	background: transparent url(../images/cancel.png) center/30px auto no-repeat;   opacity: 1;
    padding: 7px;
    border-radius: 100%;
    font-size: 16px;
}
img.delete-img {
    max-width: 145px;
}

/*-----------------
	8. Accordion Css
-----------------------*/
.accordion button.accordion-button , .accordion button.accordion-button:focus{
	outline: none;
	box-shadow: none;
}
.accordion .accordion-item {
	margin-bottom: 14px;
    border: none;
}
.accordion .accordion-button {
	font-weight: 500;
}

/*-----------------
	9. Custome Spacing Css
-----------------------*/
.mlr-10 {
    margin: 0px 10px;
}

/*-----------------
	10. Loader Css
-----------------------*/

.divLoader {
    background: rgb(0 0 0 / 51%);
    height: 100%;
    opacity: .3;
    position: fixed;
    width: 100%;
    z-index: 9;
    z-index: .2;
    top:0;
    left: 0;
}

.svgLoader {
    background-color: #faebd7;
    background: #fff;
    border-radius: 22px;
    left: 50%;
    position: fixed;
    top: 35%;
    z-index: 9999;
}


/*-----------------
	11. Responsive
-----------------------*/ 

@media (max-width: 1200px) {
   
 
}
@media (min-width: 992px) {
   
}
@media (min-width: 1260px) {
	.breadcrumb-bar {
		padding: 15px 40px;
	}
}
@media only screen and (max-width: 991.98px) {
	.container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.section-header h2 {
		font-size: 29px;
	}
	.owl-nav button.owl-prev {
		left: 6px;
	}
	.owl-nav button.owl-next {
		right: 6px;
	}
	.desktop-wrapper {
		display: none;
	}	
	.mob-wrapper {
		display: block;
	}
	.tab-style-4 .nav-item {
		width: 50%;
	}
	.delete-more {
		padding-top: 0px;
		margin-bottom: 13px;
	}
	body .theiaStickySidebar {
		position: relative !important;
        top: 0px !important;
	}
	.text-add-btn {
		text-align: left;
		margin-bottom: 11px;
	}
}

@media only screen and (max-width: 767px) {
	.section-header h2 {
		font-size: 27px;
	}
	body .owl-theme .owl-dots .owl-dot {
		display: none;
	}		
	body h2 {
		font-size: 24px;
	}
	.breadcrumb-bar .breadcrumb-title {
	   font-size: 17px;
	}
	.btn.mb-xs-10 {
		margin-bottom: 10px;
		margin-right: 0px;
	}
	/*-----------------
	column mob setting
	-----------------------*/ 
	.row-col-mob-style  .col-xs-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-col-mob-style  .col-xs-10 {
		flex: 0 0 auto;
		width: 80%;
	}
	.row-col-mob-style  .col-xs-2 {
		flex: 0 0 auto;
		width: 20%;
	}
	.tab-style-4 .nav-item {
		width: 100%;
	}
	.mob-sticky-button{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
		margin: 0 !important;
		border-radius: 10px 10px 0px 0px;
		
    }
    .mob-sticky-button .btn , .mob-sticky-button a, .mob-sticky-button button{
		width: 100%;
		min-height: 55px;
		padding: 0px !important;
		line-height: 55px;
	}
	.pagination {
		display: block;
		width: 100%;
		margin: 5px auto 25px;
		text-align: center;
	}
	ul.pagination li {
		display: inline-block;
	}
	.footer-one {
		padding-bottom: 30px;
	}
	.mob-text-center  {
		text-align: center;
		float: none;
	}
}

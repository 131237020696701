
/*============================
1. General
2. top 
3. Main header
4. Mobile Menu
5. Footer
6. Respsonsive
 ============== */ 

/*-----------------
	1. General
-----------------------*/ 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');



/*-----------------
	2. top header
-----------------------*/ 
.home-three-top {
    background: #0071DC;
    padding: 2px 30px;
    min-height: 50px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
  .header-top ul {
    display: flex;
    margin: 0;
    list-style: none;
    padding: 0;
  }
  .home-three-top.header-top ul li {
    padding-right: 20px;
    margin-right: 20px;
    color: #FFFFFF;
    font-size: 16px;
    position: relative;
  }
  .home-three-top .right-top ul li {
    padding-right: 14px;
    border: none;
    margin: 0;
  }
  .home-three-top.header-top ul li:last-child {
    padding: 0;
    margin: 0;
}
.home-three-top.header-top  .headline-header ul li {
    font-size: 18px;
    color: #f7cd1a;
    padding-top: 4px;
    font-weight: 600;
}
  /* .home-three-top.header-top ul li:first-child:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 1px;
    background: #fff;
    top: 13px;
    right: 0px;
} */
  .home-three-top .right-top ul li a {
    color: #fff;
  }
  .header-top .right-top {
    margin-left: auto;
    float: right;
  }
  .header-top .right-top img {
    max-width: 53px;
    }
    .header-top .right-top .startup-india-img {
        max-width: 70px;
    }
    .header-top  .left-top {
        padding-top: 5px;
    }
    .header-top ul li.pt-13 {
        padding-top: 13px !important;
    }
    .header-top ul li.pt-8 {
        padding-top: 8px !important;
    }
  .feather-mail:before {
    content: "\e98a";
  }
  .feather-phone:before {
    content: "\e9a7";
  }
 
 .header-top  .blink-text {

    animation: opacity 1s ease-in-out infinite;
    opacity: 1
}
.header-top  .blink-text img {
    max-width: 73px;
}
@keyframes opacity {
    0% {
        opacity: 1;
      }
    
      50% {
        opacity: 0.6
      }
    
      100% {
        opacity: 1;
      }
}
/*-----------------
	3. Main header
-----------------------*/ 
header.header {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    position: relative;
    height: 88px;
    padding: 0 30px;
    margin-bottom: 0;
}
header , body header a{
 font-family: 'Montserrat', sans-serif !important;
}
.paward-by-logo {
    padding-top: 9px;
}
body .navbar.scrolling-navbar {
   border-bottom: 1px solid #f0f0f0;
   box-shadow: none;
   padding: 0px 30px;
   background: #182388;
}
.nav-bg .main-menu-wrapper {
    display: flex;
}
nav.navbar-light img {
    max-width: 179px;
}
.nav-bg .main-menu-wrapper {
  display: flex;
}
.header-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
 display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 85px;
  padding: 0px;
  margin-bottom: 0;
}
.home-three-nav .header-nav {
  height: 85px;
  padding: 0px;
  background: #fff;
} 
.nav-bg .navbar-header {
  margin-top: 0px;
  margin: 0 auto;
}
.nav-bg .main-menu-wrapper {
  display: flex;
  padding: 0px;
 
}
.menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #f0f0f0;
  display: none;
}
.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-navbar-rht.right-menu li {
  border-right: 0;
}
header .navbar {
   box-shadow: none;
  width: 100%;
}
.nav-bg .main-nav .has-submenu.active > a {
  color: #628d30;
}
.home-three-nav .black-font > li > a {
    color: #3E5463;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}
/* .home-three-nav .main-nav .has-submenu.active > a:after {
  content: "";
  position: absolute;
  width: 70px;
  background: #0071DC;
  left: -7px;
  height: 3px;
  z-index: 9;
  bottom: 20px;
} */
.main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  font-weight: 500;
  text-decoration: none;
}
.nav-bg .main-nav li .submenu a:hover {
    color: #0071DC;
    letter-spacing: 0.5px;
    padding-left: 20px;
}
.nav-bg .header-navbar-rht {
    margin: 0;
    padding: 0;
    padding-top: 16px;
}
#ex4 .fa-stack-1x, .fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    font-size: 19px;
    width: 100%;
}
#ex4 span.cart-count {
    position: absolute;
    right: 27%;
    top: 16%;
    content: attr(data-count);
    font-size: 43%;
    width: 15px;
    padding: 0.2em;
    height: auto;
    border-radius: 50%;
    line-height: 1em;
    color: white;
    background: rgba(255, 0, 0, .85);
    text-align: center;
    min-width: 1em;
    font-size: 13px;
}
#ex4 i {
    color: #2E3842;
}
#ex4 i:hover {
    color:#4caf50 ;
}
#ex4 i:after {
    position: absolute;
    right: 27%;
    top: 21%;
    content: attr(data-count);
    font-size: 43%;
    width: 15px;
    padding: 0.2em;
    height: auto;
    border-radius: 50%;
    line-height: 1em;
    color: white;
    background: rgba(255, 0, 0, .85);
    text-align: center;
    min-width: 1em;
}
.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}
.btn-two {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    background: #222b78;
    border: 1px solid #222b78;
    box-shadow: inset 0 0 0 #fff;
    border-radius: 5px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
}
.btn-two:hover {
    color: #242424;
    border: 1px solid #242424;
    background: #ffffff;
    box-shadow: inset 210px 0 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.main-nav li.login-link {
    display: none;
}
a.logo-wrapper.waves-effect {
    display: inline-block;
    margin-right: 8px;
    width: 86px;
}
.brand-logo {
    display: inline-flex;
}

.paward-by-logo img {
    max-width: 69px;
    float: left;
}
.paward-by-logo .startup-india-img {
    margin-left: 11px;
    padding-top: 10px;
}

/*-----------------
	4. Mobile Menu
-----------------------*/

.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1040;
}
.menu-opened .main-menu-wrapper {
    transform: translateX(0);
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
    height: 60px;
    padding: 0px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    border-bottom: 1px solid #f0f0f0;
	display: none;
}
.menu-logo img {
    height: 40px;
}
.menu-close {
	font-size: 18px;
}
.bar-icon {
    display: inline-block;
    width: 31px;
}
.bar-icon span {
    background-color: #0071DC;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
}
.bar-icon-one span {
	background-color: #0de0fe;
}
.bar-icon span:nth-child(2) {
    width: 16px;
}
.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}
.blue-bar span {
    background-color: #0071dc;
}
#mobile_btn {
	display: none;
	margin-right: 30px;
}
html.menu-opened body {
    overflow: hidden;
}

.main-page-content {
    padding: 20px 30px;
}


/*-----------------
    5. Footer
-----------------------*/

.footer-one {
	/* background: url(../images/footer-bg1.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background: #182388;
}
.footer-logo img {
    max-width: 100px;
    display: inline;
}
.footer-one a {
    text-decoration: none;
}
.footer .footer-top {
    padding: 40px 0;
}

.footer-one .footer-widget .footer-logo {
    margin-bottom: 20px;
}
.footer-one .footer-widget .footer-about-content p {
    margin-bottom: 20px;
}
.footer-one .social-icon ul li a:hover {
	color: #B3BCC9;
}
.footer-one .footer-menu ul li a {
	color: #B3BCC9;
	padding-left: 0px;
}
.footer-one .footer-widget.footer-menu ul li a:hover {
	padding-left: 15px;
}
.footer-one .footer-menu ul li a::before {
	display: none;
}
.footer-one .footer-contact-info, .footer-one .footer-contact-info  a {
	color: #fff;
}
.footer-one .footer-contact-info i {
	font-size: 20px;
	color: #fff;
}

.footer-one .footer-bottom .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.27);
}
.footer-one .policy-menu li a:hover, .policy-menu li a:focus {
    color: #B3BCC9;
}
.footer-title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: capitalize;
}
.footer-one .footer-contact-info {
    color: #B3BCC9;
    font-size:15px !important;
}
.footer-one .footer-widget .footer-logo {
    margin-bottom: 20px;
}
.footer .footer-widget .footer-about-content p {
    color: #fff;
}
.footer .social-icon ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer .social-icon ul li {
    margin-right: 15px;
}
.footer .social-icon ul li a {
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
    transition: all 0.4s ease 0s;
}
.footer-contact-info .footer-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.footer-one .footer-contact-info i {
    font-size: 20px;
    color: #fff;
}
.footer .footer-bottom .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.27);
    padding: 30px 0;
}
.footer .footer-widget li a , .footer  p, .footer  a{
    color: #fff;
}
.footer .footer-widget ul {
    margin: 0;
    padding: 0;
}
.footer .footer-widget li {
    list-style: none;
    padding: 7px 0px;
}
.footer .remove-bold {
    font-weight: 400;
    line-height: 1.9;
}
.copyright-menu li a, .copyright-text p {
    color: #fff !important;
    font-size: 15px;
}
.policy-menu {
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: right;
}
.policy-menu li::after {
    color: #fff;
    content: "|";
    font-weight: 300;
    position: relative;
    left: 10px;
}
.policy-menu li  {
    display: inline-block;
    padding: 0 10px;
}
.footer-one .policy-menu li a:hover, .policy-menu li a:focus {
    color: #B3BCC9;
} 
.policy-menu li:last-child::after {
    content: "";
}
.footer-contact .fa {
    margin-right: 10px;
}
.footer .footer-widget ul li a:hover {
    color: #fff;
    letter-spacing: 0.5px;
    padding-left: 15px;
} 


/*-----------------
	6. Responsive
-----------------------*/ 

@media (max-width: 1200px) {
    .nav-bg .main-menu-wrapper {
        padding: 0 1px;
    }
    .home-three-nav .black-font > li > a ,.btn-two{
        font-size: 14px;
    }
 
}
@media (min-width: 992px) {
    .main-nav > li {
        margin-right: 20px;
    }
    
  .main-nav li > ul {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 3px rgb(0 0 0 / 10%);
      display: block;
      font-size: 14px;
      left: 0;
      margin: 0;
      min-width: 200px;
      opacity: 0;
      padding: 0;
      position: absolute;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px);
      top: 100%;
      visibility: hidden;
      z-index: 1000;
    }
    .main-nav li .submenu::before {
        border: 7px solid #fff;
        border-color: transparent transparent #fff #fff;
        box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
        content: "";
        left: 45px;
        position: absolute;
        top: 2px;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
   
    .main-nav > li:last-child {
        margin-right: 0;
    }
    .main-nav li {
        display: block;
        position: relative;
    }
    .main-nav > li > a {
        line-height: 83px;
        padding: 0 !important;
    }
    .header-nav-one .main-nav > li > a {
        line-height: 97px;
    }
    .header-four .main-nav > li > a {
        line-height: 80px;
        color: #fff;
    }
    .main-nav > li > a > i {
        font-size: 12px;
        margin-left: 3px;
    }
    .main-nav li > ul {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
        display: block;
        font-size: 14px;
        left: 0;
        margin: 0;
        min-width: 200px;
        opacity: 0;
        padding: 0;
        position: absolute;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        top: 100%;
        visibility: hidden;
        z-index: 1000;
    }
    .main-nav li .submenu::before {
        border: 7px solid #fff;
        border-color: transparent transparent #fff #fff;
        box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
        content: "";
        left: 45px;
        position: absolute;
        top: 2px;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    .main-nav li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
@media (min-width: 1260px) {
    .main-nav > li {
        margin-right: 30px;
    }
  
}
@media only screen and (max-width: 1200px) {
    header.header {
        padding: 0px 1px;
    }
    .home-three-top.header-top ul li {
        font-size: 14px;
    }
    .home-three-top.header-top .container-fluid {
        padding-left: 11px;
        padding-right: 6px;
    }
    .home-three-top {
        padding: 2px 10px;
    }    
    .home-three-top .right-top ul li {
        padding-right: 3px;
    }
}
@media only screen and (max-width: 991.98px) {
   
    .nav-bg .main-menu-wrapper {
        padding: 0px;
    }
    .header-top {
        min-height: auto;
        padding: 6px;
    }
    .header-top .right-top {
       display: none;
    }
    a.menu-logo img {
        max-width: 76px;
        margin-right: 10px;
        height: auto;
    }
    
    
    .main-nav ul {
        background-color: #0071dc;
        /* display: none; */
        list-style: none;
        margin: 0;
        padding-left: 0;
    }
    .main-nav > li {
        border-bottom: 1px solid rgb(196 196 196 / 93%);
        margin-left: 0;
        padding: 14px 9px;
    }
    .main-nav li + li {
        margin-left: 0;
    }
    .main-nav > li > a {
        line-height: 1.5;
        padding: 15px 20px !important;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
    .main-nav > li > a > i {
        float: right;
        margin-top: 5px;
    }
    .main-nav > li .submenu li a {
        border-top: 0;
        color: #fff;
        padding: 10px 15px 10px 35px;
    }
    .main-nav > li .submenu ul li a {
        padding: 10px 15px 10px 45px;
    }
    .main-nav > li .submenu > li.has-submenu > a::after {
        content: "\f078";
    }
    .main-nav .has-submenu.active > a {
        color: #fff;
    }
    .main-nav .has-submenu > a:hover {
        color: #fff;
    }
    .main-nav .has-submenu.active .submenu li.active > a {
        color: #fff;
    }
    .home-three-nav .black-font > li > a, .btn-two {
        color: #fff;
    }
    .login-left {
        display: none;
    }
    .main-menu-wrapper {
        order: 3;
        width: 260px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1060;
        transform: translateX(-260px);
        transition: all 0.4s;
        background-color: #0071DC;
    }
    body.open-menu .main-menu-wrapper {
        left: 260px;
    }
    .menu-header, .nav-bg .main-menu-wrapper , .main-nav{
        display: block;
    }
    .main-nav > li > a {
        line-height: 1.5;
        padding: 15px 20px !important;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
    .navbar-header {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
    .menu-header {
        height: 88px;
        padding: 8px 20px;
        background: #fff;
    } 
    .menu-header  a#menu_close {
        float: right;
        padding-top: 10px;
    }
    
    
    #mobile_btn {
        display: inline-block;
        margin-right: 0;
    }
    header.header {
        width: 100%;
        padding: 0px;
    }
    .footer-widget {
        margin-bottom: 30px;
    }
    .policy-menu {
        text-align: left;
    }
    .footer-title {
        font-size: 17px;
    }   
    .nav-bg .header-navbar-rht {
        display: block;
        margin: 0px 19px;
    }
    .paward-by-logo img {
        max-width: 53px;
        float: left;
    }
    .paward-by-logo {
        padding-top: 10px;
    }
    a.logo-wrapper.waves-effect {
        width: 57px;
    }
    .copyright-text p, .policy-menu {
       text-align:center; 
    }
    .header-top ul {
        display: block;
    }
    .header-top .left-top {
        padding-top: 0px;
    }
    .home-three-top.header-top ul li:first-child:before  {
        display: none;
    }
    .header-top.home-three-top .col-md-8 {
        width: 100%;
    }
  
}

@media only screen and (max-width: 767px) {
    .home-three-top.header-top ul li {
        width: 100%;
        float: none;
        margin: 0px
    }
    .home-three-top.header-top ul li:last-child {
        text-align: center;
    }
}